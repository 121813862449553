<template>
  <div>
    <div class="mb-2 items-center">
      <vs-button class="ml-auto" @click="popupAddCoupon = true; editMode = false" size="small">Add New Coupon</vs-button>
    </div>
    <vx-card no-shadow>
      <vs-table
        :max-items="descriptionItems[0]"
        pagination
        :data="coupons"
        description
        search
        :description-items="descriptionItems"
        description-title="Registries"
        description-connector="of"
        description-body="Pages">

        <template slot="header">
          <h5 class="flex items-center mb-2">
            <feather-icon icon="TagIcon" class="w-5 h-5 mr-1"></feather-icon>
            <span>Coupons </span>
          </h5>
        </template>
        <template slot="thead">
          <vs-th>
            Code
          </vs-th>
           <vs-th>
            Multiplier
          </vs-th>
          <vs-th>
            Start
          </vs-th>
          <vs-th>
            End
          </vs-th>
          <vs-th>
            Active
          </vs-th>
          <vs-th>
            Action
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].code">
              {{data[indextr].code}}
            </vs-td>
            <vs-td :data="data[indextr].multiplier">
              {{data[indextr].multiplier}}
            </vs-td>
            <vs-td :data="data[indextr].start">
              {{data[indextr].start | date(true)}}
            </vs-td>
            <vs-td :data="data[indextr].end">
              {{data[indextr].end | date(true)}}
            </vs-td>
             <vs-td :data="data[indextr].is_active">
              <vs-chip :color="getStatusColor(tr.is_active)">{{ tr.is_active? '✓' :'X' }}</vs-chip>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="editCoupon(tr)" />
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteCoupon(tr.id)" />
              </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup class="holamundo" @close="close" :title="editMode ? 'Edit Coupon':'Add New Coupon'" :active.sync="popupAddCoupon">
        <form>
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-input v-validate="{ required: true}" label="Name *" placeholder="Coupon Code" name="coupon_code" v-model="newCoupon.code" class="w-full" />
              <span class="text-danger text-sm"  v-show="errors.has('coupon_code')">{{ errors.first('coupon_code') }}</span>
            </div>
          </div>
          <div class="vx-row items-end">
            <div class="vx-col md:w-1/2 w-full">
              <vs-input  v-model="newCoupon.multiplier"  v-validate="{ required: true, regex: /^[0-9.]+$/}" type="number" label="Multiplier *" name="multiplier" class="mt-5 w-full" placeholder="e.g 0.1 for 10%" />
              <span class="text-danger text-sm" v-show="errors.has('multiplier')">{{ errors.first('multiplier') }}</span>
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <vs-checkbox name="is_active" class="inline-flex" v-model="newCoupon.is_active">Active</vs-checkbox>
            </div>
          </div>
          <div class="mt-4">
            <label class="text-sm">Start Date</label>
            <flat-pickr v-model="newCoupon.start" :config="flatPickConfig" class="w-full" placeholder="Start date" name="start" />
            <span class="text-danger text-sm"  v-show="errors.has('start')">{{ errors.first('start') }}</span>
          </div>
          <div class="mt-4">
            <label class="text-sm">End Date</label>
            <flat-pickr v-model="newCoupon.end" :config="flatPickConfig" class="w-full" placeholder="End date" name="end" />
            <span class="text-danger text-sm"  v-show="errors.has('end')">{{ errors.first('end') }}</span>
          </div>
          <div class="vx-col w-full">
            <div class="mt-6">
              <vs-textarea label="Description" rows="2" name="description" v-model="newCoupon.about" placeholder="Enter description" />
            </div>
          </div>
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="editMode ? updateCoupon(): createCoupon()" size="small">{{editMode ? 'Update':'Add Coupon'}}</vs-button>
        </form>
      </vs-popup>
    </vx-card>
  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,flatPickr
  },
  data() {
    return {
      descriptionItems: [5,15,50],
      newCoupon: {
        code: "",
        multiplier: null,
        start: null,
        end: null,
        is_active: false,
        description: ""
      },
      flatPickConfig:{
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
      popupAddCoupon: false,
      editMode: false,
      awaitingUpdate: "",
      awaitingDelete: "",
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && (this.newCoupon.code != "")
    },
    coupons(){
      return this.$store.state.coupons
    }
  },
  methods: {
    createCoupon(){
      let formData = new FormData()
      formData.append('data', JSON.stringify(this.newCoupon))
      this.resquestProcessor(formData, 'create')
    },
    updateCoupon(){
      let formData = new FormData()
      formData.append('data', JSON.stringify(this.newCoupon))
      this.resquestProcessor(formData,`${this.awaitingUpdate}/update`, 'UPDATE')
    },
    resquestProcessor(formData, action, ftn='ADD'){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/coupons/${action}`,formData)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.initializeAll()
          this.$store.commit(`${ftn}_COUPON`, response.data.coupon)
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
     }).catch((error)=>{console.log( error) })
    },
    fetchCoupons() {
      this.$http.get(`/coupons`)
        .then((response) => {
          if(response.data.success){
            this.$store.commit('SET_COUPONS', response.data.coupons)
          }else{
            this.alertError(response.data.error)
          }
        }).catch((error) => { console.log(error) })
    },
    editCoupon(coupon){
      this.editMode = true
      this.newCoupon =  {
        code: coupon.code,
        multiplier: coupon.multiplier,
        start: new Date(coupon.start),
        end: new Date(coupon.end),
        is_active: coupon.is_active ? true : false,
        description: coupon.description
      }
      this.popupAddCoupon = true
      this.awaitingUpdate = coupon.id
    },
    deleteCoupon(id){
      this.awaitingDelete = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'The selected coupon will be removed!',
        accept: this.acceptDelete
      })
    },
    acceptDelete(){
      if(!this.awaitingDelete){return}
      this.$http.delete(`/coupons/${this.awaitingDelete}/delete`)
      .then((response) =>{
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.$store.commit('DELETE_COUPON', response.data.couponId)
          this.initializeAll();
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error)=>{console.log(error)})

    },
    initializeAll(){
      this.newCoupon = {
        code: "",
        multiplier: null,
        start: null,
        end: null,
        is_active: false,
        description: ""
      }
      this.popupAddCoupon = false
      this.editMode = false
      this.awaitingUpdate = ""
      this.awaitingDelete = ""
      this.$validator.reset()
    },
    close(){
      this.$vs.notify({
      title:'Notice',
      text:"You cancelled an operation",
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'bottom-right'})
      this.initializeAll()
    },
  },
  created(){
     if(!this.coupons.length){
      this.fetchCoupons()
    }
  }
}
</script>
